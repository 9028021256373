import {useEffect, useState} from 'react';
import cn from 'classnames';
import type {VariantProps} from 'class-variance-authority';

import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import BlogSection from '@/components/pages/blog/BlogSection/BlogSection';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import type {
  Article,
  GroupedArticlesListProps,
  BlogSectionProps,
} from '@/components/blog/types';
import type {Mode} from '@/components/base/types';

import {
  articleCarouselDotsItem,
  articleCarouselDotsWrapper,
  articleCarouselItemStyles,
  articleCarouselStyles,
} from './style';

interface ArticleCarouselProps
  extends GroupedArticlesListProps,
    VariantProps<typeof articleCarouselStyles>,
    BlogSectionProps {
  carouselName: string;
  preferredBlogCardTag?: string;
  blogCardMode?: Mode;
}

export default function ArticleCarousel({
  primaryArticles,
  enBlogPath,
  heading,
  headingTag,
  link,
  carouselName,
  className,
  mode = 'blogBP',
  preferredBlogCardTag,
  blogType,
  blogCardMode,
}: ArticleCarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            setActiveIndex(
              [...entry.target.parentNode!.children].indexOf(entry.target),
            );
          }
        });
      },
      {threshold: 0.7},
    );

    document.querySelectorAll(`.${carouselName}`).forEach((card) => {
      observer.observe(card);
    });
  }, [carouselName]);

  return (
    <BlogSection
      heading={heading}
      link={link}
      containerStyles={
        mode === 'default' ? 'max-lg:!mr-0' : 'max-desktop:!mr-0'
      }
      className={className}
      headingTag={headingTag}
    >
      <div className={articleCarouselStyles({mode})}>
        {primaryArticles.map((article: Article) => (
          <div
            key={`card-${article.handle}`}
            className={cn(carouselName, articleCarouselItemStyles({mode}))}
          >
            <BlogCard
              blogType={blogType}
              article={article}
              enBlogPath={enBlogPath}
              type="grid-4"
              mode={blogCardMode}
              preferredTag={
                isEnterpriseBlog ? article.topicHandle : preferredBlogCardTag
              }
            />
          </div>
        ))}
      </div>
      <div className={articleCarouselDotsWrapper({mode})}>
        {primaryArticles.map((_, index) => (
          <div
            key={`indicator-${index}`}
            className={articleCarouselDotsItem({
              isActive: activeIndex === index,
              blogType,
            })}
          />
        ))}
      </div>
    </BlogSection>
  );
}
