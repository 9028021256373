import {cva} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const articleCarouselStyles = cva(
  [
    'flex justify-start flex-nowrap',
    'max-w-full overflow-x-auto snap-x snap-mandatory gap-x-gutter pr-[var(--margin)]',
  ],
  {
    variants: {
      mode: {
        default: [
          `lg:flex-wrap lg:justify-center lg:overflow-hidden`,
          `lg:gap-y-gutter lg:mb-gutter`,
          `lg:mx-auto lg:py-4 lg:pr-0`,
        ],
        blogBP: [
          'desktop:flex-wrap desktop:justify-center desktop:overflow-hidden`',
          'desktop:gap-y-gutter desktop:mb-gutter',
          'desktop:mx-auto desktop:py-4  desktop:pr-0',
        ],
      },
    },
  },
);

export const articleCarouselItemStyles = cva(
  'support-card shrink-0 snap-center w-[296px]',
  {
    variants: {
      mode: {
        default: 'lg:basis-1/4-gutter',
        blogBP: 'desktop:basis-1/4-gutter',
      },
    },
  },
);

export const articleCarouselDotsWrapper = cva(
  'mobile-indicators mt-6 flex justify-center gap-x-2',
  {
    variants: {
      mode: {
        default: 'lg:hidden',
        blogBP: 'desktop:hidden',
      },
    },
  },
);

export const articleCarouselDotsItem = cva('rounded-full box-content w-2 h-2', {
  variants: {
    isActive: {
      true: 'bg-black',
      false: 'bg-[#d9d9d9]',
    },
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: 'w-2 h-2',
      [BLOG_PAGE_TYPE.RETAIL]: 'w-2 h-2',
      [BLOG_PAGE_TYPE.PARTNERS]: 'w-2 h-2',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'w-[10px] h-[10px]',
      [BLOG_PAGE_TYPE.ENGINEERING]: 'w-2 h-2',
    },
  },
  compoundVariants: [
    {
      isActive: true,
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: '!bg-[#E7ECFB]',
    },
    {
      isActive: false,
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: '!bg-[#9AA7C8]',
    },
  ],
});
