/* eslint-disable jsx-a11y/anchor-has-content */
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';
import type {BlogSectionProps} from '@/components/blog/types';
import Link from '@/components/pages/blog/Link/Link';
import {
  headingSize,
  headingStyles,
} from '@/components/pages/blog/BlogSection/styles';

export default function BlogSection({
  heading,
  headingTag = 'h4',
  link,
  className,
  containerStyles,
  children,
}: BlogSectionProps) {
  return (
    <div
      className={twMerge(
        'py-10 tablet-xl:py-16',
        cn({'desktop:py-20': headingTag === 'h4'}),
        className,
      )}
    >
      <div className="container">
        <div className={headingStyles({headingTag})}>
          <Typography className={headingSize({headingTag})} as={headingTag}>
            {heading}
          </Typography>
          {link && (
            <Link
              className="hidden tablet:inline-flex header-link"
              link={link}
            />
          )}
        </div>
      </div>
      <div className={twMerge('container', containerStyles)}>
        {children}
        {link && (
          <Link className="tablet:hidden mt-9 header-link" link={link} />
        )}
      </div>
    </div>
  );
}
