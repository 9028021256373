import {cva, cx} from 'class-variance-authority';

export const subHeaderStyles = cx(
  'tracking-[-.02em]',
  'font-bold tablet:font-medium desktop:font-bold',
  'mt-9 tablet-xl:mt-0',
  'text-[18px] leading-[26px]',
  'tablet:text-xl',
  'desktop:text-2xl',
  'blog-section-header',
);

export const headingSize = cva('tracking-[-.02em] blog-section-header', {
  variants: {
    headingTag: {
      h3: cx('text-t5 font-faktum', 'desktop:text-t3', 'font-medium'),
      h4: cx(
        'text-[32px] leading-[40px]',
        'desktop:text-[40px] desktop:leading-[48px] desktop:tracking-[-.01em]',
        'font-bold',
      ),
      h5: cx(
        'text-[26px] leading-[30px]',
        'tablet:text-[24px] tablet:leading-[32px]',
        'font-bold tablet:font-medium',
      ),
      h6: cx(
        'text-[24px] leading-[30px]',
        'tablet:text-[20px] tablet:leading-[28px]',
        'font-bold tablet:font-medium desktop:font-bold',
      ),
    },
  },
});

export const headingStyles = cva('flex justify-between items-center', {
  variants: {
    headingTag: {
      h3: 'mb-9',
      h4: 'mb-16',
      h5: 'mb-9',
      h6: 'mb-9',
    },
  },
});
